import React from 'react';

const Tamizaje = () => {
  return (
    <div className="container">
      <div className="wow fadeIn">
        <h1 className="pt-5 pb-3 text-center" style={{ color: '#444444' }}>
          <b>SU PLAN DE DETECCIÓN TEMPRANA HA SIDO ENVIADO A SU CORREO</b>
        </h1>
        <br />
        <h3>
          Si no puede ver el correo en su bandeja de entrada, le sugerimos revisar en su bandeja de correos no deseados
        </h3>
        <br />
      </div>
      <div className="row" id="botonesTamizaje">
        <div className="col-lg-4 flex-center">
          <a href="https://wa.link/j06rkg" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
            CONTACTAR POR WHATSAPP
          </a>
        </div>
        <div className="col-lg-4 flex-center">
          <a href="mailto:info@centrooncologico.cr" className="btn btn-primary">
            CONTACTAR POR MAIL
          </a>
        </div>
        <div className="col-lg-4 flex-center">
          <button className="btn btn-primary" type="button" onClick={() => window.print()}>
            DESCARGAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tamizaje;
