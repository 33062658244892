export default {
  'San José': [
    'San José',
    'Escazú',
    'Desamparados',
    'Puriscal',
    'Tarrazu',
    'Aserrí',
    'Mora',
    'Goicoechea',
    'Santa Ana',
    'Alajuelita',
    'Vazquez de Coronado',
    'Acosta',
    'Tibás',
    'Moravia',
    'Montes de Oca',
    'Turrubares',
    'Dota',
    'Curridabat',
    'Pérez Zeledón',
    'León Cortes',
  ],
  Alajuela: [
    'Alajuela',
    'San Ramon',
    'Grecia',
    'San Mateo',
    'Atenas',
    'Naranjo',
    'Palmares',
    'Poás',
    'Orotina',
    'San Carlos',
    'Alfaro Ruiz',
    'Valverde Vega',
    'Upala',
    'Los Chiles',
    'Guatuso',
  ],
  Cartago: ['Cartago', 'Paraíso', 'La Unión', 'Jiménez', 'Turrialba', 'Alvarado', 'Oreamuno', 'El Guarco'],
  Heredia: [
    'Heredia',
    'Barva',
    'Santo Domingo',
    'Santa Bárbara',
    'San Rafael',
    'San Isidro',
    'Belén',
    'San Joaquín de Flores',
    'San Pablo',
    'Sarapiquí',
  ],
  Guanacaste: [
    'Liberia',
    'Nicoya',
    'Santa Cruz',
    'Bagaces',
    'Carrillo',
    'Cañas',
    'Abangares',
    'Tilarán',
    'Nandayure',
    'La Cruz',
    'Hojancha',
  ],
  Puntarenas: [
    'Puntarenas',
    'Esparza',
    'Buenos Aires',
    'Montes de Oro',
    'Osa',
    'Aguirre',
    'Golfito',
    'Coto Brus',
    'Parrita',
    'Corredores',
    'Garabito',
  ],
  Limón: ['Limón', 'Pococí', 'Siquirres', 'Talamanca', 'Matina', 'Guácimo'],
};
