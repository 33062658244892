import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { Antecedente } from '../../models';

export interface AntecedentesContainerProps {
  antecedentes: Antecedente[];
}

export const AntecedentesContainer = createContainer(
  (initialState: AntecedentesContainerProps = { antecedentes: [] }) => {
    const [state, setState] = useState<AntecedentesContainerProps>(initialState);

    const addAntecedentes = (values: []) => {
      setState({ antecedentes: [...state.antecedentes, ...values] });
    };

    return { state, setState, addAntecedentes };
  }
);
