import React from 'react';

export const Footer = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h4>PARA INFORMACIÓN SOBRE PLANES DE DETECCIÓN TEMPRANA O CITAS</h4>
      <h4>
        <a href="www.centrooncologico.cr">WWW.CENTROONCOLOGICO.CR</a> · +506 2296 9638 ·{' '}
        <a href="mailto:info@centrooncologico.cr">INFO@CENTROONCOLOGICO.CR</a>
      </h4>
    </div>
  );
};
