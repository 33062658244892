import React, { useState } from 'react';

import { Grid, FormControl, FormHelperText, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { CondicionesModal } from './modal';

import { db } from '../../../firebase';
import {
  Escolaridad,
  EstadoCivil,
  Genero,
  PacienteInitialValues,
  PacienteValidationSchema,
  RangoEdades,
  TipoIden,
} from '../../../models';
import paises from '../../../models/paises';
import regionesCr from '../../../models/regiones-cr';
import FormSelectCantonField from './form-select-canton-field';
import FormSelectField from './form-select-field';
import FormTextField from './form-text-field';
import { PacienteContainer } from '../../../containers/paciente';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { subYears } from 'date-fns';

const handleProvinciaChange = (actions) => (e) => {
  actions.setFieldValue('Canton', '', true);
  actions.handleChange(e);
};

const handleFechaNacimientoChange = (actions) => (e: Date | null) => {
  let edadValue: undefined | string = '';
  if (e) {
    const bornYear = e.getUTCFullYear();
    const todayYear = new Date().getUTCFullYear();
    const age = todayYear - bornYear;
    if (age < 15) {
      edadValue = age.toString();
    } else {
      const rangosEdades = Object.values(RangoEdades);
      edadValue = rangosEdades.find((rangoEdades) => {
        const limits = rangoEdades.split(/\+|-/);
        if ((limits[0] && +limits[0] <= age && age <= +limits[1]) || (!limits[0] && +limits[1] <= age)) {
          return true;
        }
        return false;
      });
    }
    actions.setFieldValue('fechaNacimiento', e);
  }
  actions.setFieldValue('edad', edadValue);
};

const FormUserData = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const paciente = PacienteContainer.useContainer();
  const habilitarFormulario = () => {
    setIsEditing(true);
    setIsSubmitted(false);
  };

  const submit = (values, actions) => {
    if (!isEditing) {
      db.doCreatePaciente(values).then((data) => {
        // seteamos setSumitting false para reactivar el formulario
        actions.setSubmitting(false);

        // Este es un contenedor que nos permitirá compartir los datos con otros
        // componentes de react
        paciente.setState({ ...values, id: data.id });

        // guardamos el usuario en el store
        setIsSubmitted(true);

        // mostrar antecedentes
        let antecedentes = document.getElementById('antecedentes');
        if (antecedentes) {
          antecedentes.style.display = '';
        }

        setTimeout(() => {
          window.location.href = '#antecedentes';
        }, 20);
      });
    } else {
      // editando
      db.doUpdatePaciente({ ...paciente.state, ...values }).then(() => {
        // seteamos setSumitting false para reactivar el formulario
        actions.setSubmitting(false);

        // Este es un contenedor que nos permitirá compartir los datos con otros
        // componentes de react
        paciente.setState({ ...values });

        // guardamos el usuario en el store
        setIsSubmitted(true);

        // mostrar antecedentes
        let antecedentes = document.getElementById('antecedentes');
        if (antecedentes) {
          antecedentes.style.display = '';
        }

        setTimeout(() => {
          window.location.href = '#antecedentes';
        }, 20);
      });
    }
  };
  return (
    <Formik initialValues={PacienteInitialValues} validationSchema={PacienteValidationSchema} onSubmit={submit}>
      {({ handleSubmit, handleChange, handleBlur, values, errors, setFieldValue, isValid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <FormSelectField
                id="tipoIdent"
                label="Tipo identificación"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                options={Object.values(TipoIden)}
                disabled={isSubmitted}
              />
              <FormTextField
                id="ident"
                label="Número de identificación"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormTextField
                id="nombre1"
                label="Primer nombre"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormTextField
                id="nombre2"
                label="Segundo nombre"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormTextField
                id="apellido1"
                label="Primer apellido"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormTextField
                id="apellido2"
                label="Segundo apellido"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormTextField
                id="email"
                label="E-mail"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormTextField
                id="telf"
                label="Teléfono"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormSelectField
                id="escolaridad"
                label="Escolaridad"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                options={Object.values(Escolaridad)}
                disabled={isSubmitted}
              />
              <FormSelectField
                id="estadoCivil"
                label="Estado civil"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                options={Object.values(EstadoCivil)}
                disabled={isSubmitted}
              />
              <FormSelectField
                id="nacionalidad"
                label="Pais de nacimiento"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                options={paises}
                disabled={isSubmitted}
              />
              <FormTextField
                id="religion"
                label="Religión"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled={isSubmitted}
              />
              <FormSelectField
                id="genero"
                label="Género de nacimiento"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                options={Object.values(Genero)}
                disabled={isSubmitted}
              />
              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    id="fechaNacimiento"
                    label="Fecha de nacimiento"
                    value={values.fechaNacimiento}
                    onChange={handleFechaNacimientoChange({
                      setFieldValue,
                    })}
                    disabled={isSubmitted}
                    error={!!errors.fechaNacimiento}
                    onBlur={handleBlur}
                    disableFuture
                    format="dd/MM/yyyy"
                    minDate={subYears(new Date(), 100)}
                    fullWidth
                    helperText={errors.fechaNacimiento}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <FormSelectField
                id="Pais"
                label="País de residencia"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                options={paises}
                disabled={isSubmitted}
              />
              {values.Pais === paises[0] && (
                <>
                  <FormSelectField
                    id="Provincia"
                    label="Provincia de residencia"
                    values={values}
                    handleChange={handleProvinciaChange({
                      handleChange,
                      setFieldValue,
                    })}
                    handleBlur={handleBlur}
                    errors={errors}
                    options={Object.keys(regionesCr)}
                    disabled={isSubmitted}
                  />
                  <FormSelectCantonField
                    id="Canton"
                    label="Cantón de residencia"
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    disabled={isSubmitted}
                  />
                </>
              )}
            </Grid>
            <Grid container>
              <Grid item>
                <div className="center-on-small-only mb-12">
                  <FormControl required error={!!errors.terminos} component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="terminos"
                          checked={values.terminos}
                          onChange={handleChange}
                          value={values.terminos ? 'checked' : ''}
                          disabled={isSubmitted}
                        />
                      }
                      label={
                        <label className="form-check-label" htmlFor="defaultCheck1">
                          He leído y acepto los términos y condiciones para poder hacer uso de esta aplicación.{' '}
                          <CondicionesModal />
                        </label>
                      }
                    />
                    <FormHelperText>{errors.terminos}</FormHelperText>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              {!isSubmitted && !isEditing && (
                <button disabled={!isValid} className="btn btn-primary ml-0 waves-effect waves-light" type="submit">
                  <i className="fa fa-paper-plane-o mr-2" />
                  Iniciar
                </button>
              )}
              {isSubmitted && (
                <button className="btn btn-primary ml-0 waves-effect waves-light" onClick={habilitarFormulario}>
                  <i className="fa fa-paper-plane-o mr-2" />
                  Editar
                </button>
              )}
              {!isSubmitted && isEditing && (
                <button className="btn btn-primary ml-0 waves-effect waves-light" type="submit">
                  <i className="fa fa-paper-plane-o mr-2" />
                  Guardar
                </button>
              )}
            </Grid>
            {isSubmitted ? (
              <Grid container justify="center" alignItems="center">
                <Typography style={{ color: '#388e3c' }}>
                  Se ha almacenado de manera correcta los datos, puede continuar
                </Typography>
              </Grid>
            ) : null}
          </form>
        );
      }}
    </Formik>
  );
};

export default FormUserData;
