import React from 'react';
import * as Tamizaje from '../../../models/tamizajes';

export const crearTamizajes = (paciente, antecedentes) => {
  const tamizajeNombres = Object.keys(Tamizaje);
  const tamizajeFinal = tamizajeNombres.reduce((acumulador, posActual) => {
    // Estos tamizajes son los grupos de tamizaje de cada tamizaje
    const tamizajes = Tamizaje[posActual].gruposTamizaje;
    if (!tamizajes) {
      return acumulador;
    }

    // este siCumpleTamizaje es un arreglo de booleanos que
    //identifica si cumple con alguno de los grupos de tamizaje
    const siCumpleTamizaje: boolean[] = [];

    // recorremos los grupos de tamizajes para validar su
    //concordancia con los datos del paciente
    tamizajes.forEach((tamizaje) => {
      // esta bandera nos dice si concuerda el tamizaje con lo que indicó el paciente
      let bandera = true;

      // comprobamos el genero del paciente
      if (tamizaje.generos && tamizaje.generos[0] !== paciente.genero) {
        bandera = false;
      }

      // comprobamos la edad del paciente
      if (tamizaje.rangosEdades) {
        if (!tamizaje.rangosEdades.includes(paciente.edad)) {
          bandera = false;
        }
      }

      if (bandera) {
        const esAplicable = Tamizaje[posActual].esAplicable;
        if (antecedentes.length) {
          bandera = esAplicable(
            antecedentes.map((antecedente) => antecedente.antecedente),
            paciente
          );
        } else {
          bandera = esAplicable([], paciente);
        }
      }

      siCumpleTamizaje.push(bandera);
    });

    if (siCumpleTamizaje.includes(true)) {
      acumulador.push(Tamizaje[posActual]);
    }
    return acumulador;
  }, [] as any);

  // inciar el return que nos va a traer los componentes de react con la info de los tamizajes
  return tamizajeFinal.map((tamizaje) => ({
    id: tamizaje.id,
    descripcion: tamizaje.descripcion,
  }));
};

export const ResultadoTamizaje = (props) => {
  if (!Object.keys(props.paciente).length) {
    return null;
  }

  const tamizajes = crearTamizajes(props.paciente, props.antecedentes);

  return tamizajes.map((tamizaje, idx) => {
    return (
      <div key={tamizaje.id} className="col-sm-12 mb-r wow fadeInUp" data-wow-delay=".3s">
        <i className="fa fa-clipboard fa-3x" style={{ color: '#677635' }}></i>
        <h3 className="mt-3" style={{ color: '#444444', textDecoration: 'underline' }}>
          RESULTADO {idx + 1}
        </h3>
        <p className="mt-3 text-left" style={{ color: '#444444' }}>
          {tamizaje.descripcion()}
        </p>
      </div>
    );
  });
};
