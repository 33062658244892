import React, { useState } from 'react';
import '../../styles/main.css';

export const Login = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username === 'Administrador' && password === 'TamizajeOncologico2021') {
      sessionStorage.setItem('loggedIn', 'TamizajeOncologico2021');
      window.location.reload();
    } else {
      setError('usuario y/o contraseña inválidos');
    }
  };

  return (
    <div className="login-wrapper">
      <h1>Por favor inicie sesión</h1>
      <form
        onSubmit={handleSubmit}
        style={{
          minWidth: '245px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <label>
          <p>Usuario</p>
          <input
            style={{ width: '100%' }}
            type="text"
            onChange={(e) => {
              setError('');
              setUserName(e.target.value);
            }}
          />
        </label>
        <label>
          <p>Contraseña</p>
          <input
            style={{ width: '100%' }}
            type="password"
            onChange={(e) => {
              setError('');
              setPassword(e.target.value);
            }}
          />
        </label>
        <button
          style={{
            alignSelf: 'center',
            marginTop: '10px',
          }}
          type="submit"
        >
          Enviar
        </button>
        {error ? <div className="errorMsg">{error}</div> : null}
      </form>
    </div>
  );
};
