import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { Paciente } from '../../models';

export const PacienteContainer = createContainer((initialState: Paciente | object = {}) => {
  const [state, setState] = useState<Paciente | object>(initialState);

  const setPacienteValue = (name: string, value: any) => {
    setState({ ...state, [name]: value });
  };

  const setLocal = (data: any) => {
    setState(data);
  };

  return { state, setPacienteValue, setState: setLocal };
});
