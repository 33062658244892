import * as yup from 'yup';
import paises from './paises';
import { Escolaridad, EstadoCivil, Genero, Paciente, TipoIden } from './';
import { sub } from 'date-fns';

export const PacienteValidationSchema = yup.object<Paciente>().shape({
  ident: yup
    .string()
    .matches(/^(\d)+$/, 'Sólo se aceptan números')
    .min(3, 'Mínimo 3 dígitos')
    .max(20, 'Máximo 20 dígitos')
    .required('Es requerido'),
  tipoIdent: yup.mixed().oneOf(Object.values(TipoIden)).required('Es requerido'),
  nombre1: yup
    .string()
    .min(2, 'No puede tener menos de 2 letras')
    .max(20, 'No puede exceder 20 letras')
    .required('Es requerido'),
  nombre2: yup.string().default(''),
  apellido1: yup
    .string()
    .min(2, 'No puede tener menos de 2 letras')
    .max(20, 'No puede exceder 20 letras')
    .required('Es requerido'),
  apellido2: yup.string().default(''),
  email: yup.string().email('Formato inválido').required('Es requerido'),
  telf: yup
    .string()
    .matches(/^(\d-?_?\s?){7}(\d)$/, 'debe contener 8 dígitos')
    .required('Es requerido'),
  escolaridad: yup.mixed().oneOf(Object.values(Escolaridad)).required('Es requerida'),
  estadoCivil: yup.mixed().oneOf(Object.values(EstadoCivil)).required('Es requerido'),
  nacionalidad: yup.string().required('Es requerido'),
  religion: yup.string(),
  genero: yup.mixed().oneOf(Object.values(Genero)).required('Es requerido'),
  fechaNacimiento: yup
    .date()
    .min(new Date(1900, 1), 'Debe ser superior a 1900')
    .max(sub(new Date(), { years: 15 }), 'Debe ser mayor a 15 años')
    .required('Es requerido'),
  edad: yup.string(),
  Pais: yup.string().required('Es requerido'),
  Provincia: yup.mixed().when('Pais', {
    is: paises[0],
    then: yup.string().required('Es requerido'),
    otherwise: yup.string().default(''),
  }),
  Canton: yup.mixed().when('Provincia', {
    is: (val) => val !== '',
    then: yup.string().required('Es requerido'),
    otherwise: yup.string().default(''),
  }),
  terminos: yup.bool().oneOf([true], 'Este campo es requerido'),
});
