import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { AntecedentesHF, AntecedentesAPPAPNP, AntecedentesAPPAPNPGenero } from '../../../models/antecedentes';
import { Sintom, SintomasGenero } from '../../../models/sintomas';

const propiedades = { AntecedentesHF, AntecedentesAPPAPNP, Sintom };

export const MostrarAntecedentes = (props) => {
  const antecedenteAUsar = propiedades[props.name];
  const { stateAntecedentes, setStateAntecedentes } = props;
  const items = Object.keys(antecedenteAUsar).reduce((acumulator, currentVal) => {
    let generoAgregar = true;
    if (props.name === 'Sintom' && SintomasGenero[currentVal] && SintomasGenero[currentVal] !== props.genero) {
      generoAgregar = false;
    }
    if (
      props.name === 'AntecedentesAPPAPNP' &&
      AntecedentesAPPAPNPGenero[currentVal] &&
      AntecedentesAPPAPNPGenero[currentVal] !== props.genero
    ) {
      generoAgregar = false;
    }
    if (generoAgregar) {
      acumulator.push(
        <div className="col-lg-4 col-md-6 text-left" key={currentVal}>
          <input
            className="form-check-input"
            type="checkbox"
            id={currentVal}
            value={currentVal}
            disabled={props.isSubmitted}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // esta es una forma igual de asignar, lo que estamos diciendo es que al antiguo
              // stateAntecedentes, le asignamos o reescribimos el atributo [name] con el valor
              // e.target.checked
              setStateAntecedentes(
                Object.assign(stateAntecedentes, {
                  [currentVal]: e.target.checked,
                })
              );
            }}
          />
          {(currentVal === 'APPAPNP_3_24' && (
            <Tooltip
              title="aluminio (Al), arsénico (As), berilio (Be), cadmio (Cd), cromo total (Cr), cromo III (Cr III o Cr3+), cromo VI (Cr VI o Cr 6+), cobre (Cu), hierro (Fe), mercurio (Hg), metilmercurio (MeHg+), manganeso (Mn), níquel (Ni), plomo (Pb), vanadio (V) y zinc (Zn)."
              placement="bottom"
            >
              <label
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                className="form-check-label"
                htmlFor={currentVal}
              >
                <b>{antecedenteAUsar[currentVal]}</b>
              </label>
            </Tooltip>
          )) || (
            <label className="form-check-label" htmlFor={currentVal}>
              <b>{antecedenteAUsar[currentVal]}</b>
            </label>
          )}
        </div>
      );
    }
    return acumulator;
  }, [] as React.ReactElement[]);
  return <>{items}</>;
};
