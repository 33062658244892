import React from 'react';

import { Grid, MenuItem, TextField } from '@material-ui/core';

import regionesCr from '../../../models/regiones-cr';

const FormSelectCantonField = (props) => (
  <Grid item xs={props.xs || 12} sm={props.sm || 4}>
    <TextField
      disabled={props.disabled}
      id={props.id}
      name={props.id}
      select
      label={props.label}
      value={props.values.Canton}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      error={!!props.errors[props.id]}
      helperText={props.errors[props.id]}
      fullWidth
      variant="standard"
    >
      <MenuItem value="" />
      {props.values.Provincia &&
        regionesCr[props.values.Provincia].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
    </TextField>
  </Grid>
);

export default FormSelectCantonField;
