import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const getAttributeData = (id, item) => {
  try {
    return id.split('.').reduce((o, i) => o[i], item);
  } catch {
    return undefined;
  }
};

const FormTextField = (props) => {
  const value = getAttributeData(props.id, props.values);
  const error = getAttributeData(props.id, props.errors);
  return (
    <Grid item xs={props.xs || 12} sm={props.sm || 4}>
      <TextField
        id={props.id}
        label={props.label}
        value={value || (props.date && new Date())}
        error={!!error}
        helperText={error}
        onBlur={props.handleBlur}
        onChange={props.handleChange}
        fullWidth
        variant="standard"
        type={props.date ? 'date' : 'text'}
        disabled={props.disabled}
      />
    </Grid>
  );
};

export default FormTextField;
