import React from 'react';
import { AntecedentesContainer } from '../../../containers/antecedentes';
import { Sintom } from '../../../models/sintomas';
import { AntecedentesHF, AntecedentesAPPAPNP } from '../../../models/antecedentes';

export const AntecedentesInfo = () => {
  const antecedentes = AntecedentesContainer.useContainer();
  const sintomas = antecedentes.state.antecedentes
    .filter((antecedente) => !!Sintom[antecedente.antecedente])
    .map((i) => Sintom[i.antecedente]);
  const anteHF = antecedentes.state.antecedentes
    .filter((antecedente) => !!AntecedentesHF[antecedente.antecedente])
    .map((i) => AntecedentesHF[i.antecedente]);
  const anteAPPAPNP = antecedentes.state.antecedentes
    .filter((antecedente) => !!AntecedentesAPPAPNP[antecedente.antecedente])
    .map((i) => AntecedentesAPPAPNP[i.antecedente]);

  return (
    <>
      <ol>
        {(anteHF.length && (
          <li>
            <span>
              ANTECEDENTES HEREDO-FAMILIARES: <b>{anteHF.join(', ')}</b>
            </span>
          </li>
        )) ||
          null}
        {(sintomas.length && (
          <li>
            <span>
              SÍNTOMAS: <b>{sintomas.join(', ')}</b>
            </span>
          </li>
        )) ||
          null}
        {(anteAPPAPNP.length && (
          <li>
            <span>
              ANTECEDENTES PERSONALES PATOLÓGICOS Y ANTECEDENTES PERSONALES NO-PATOLÓGICOS:{' '}
              <b>{anteAPPAPNP.join(', ')}</b>
            </span>
          </li>
        )) ||
          null}
      </ol>
    </>
  );
};
