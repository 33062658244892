import React, { useState } from 'react';

import { db, functions } from '../../../firebase';
import { PacienteContainer } from '../../../containers/paciente';
import { AntecedentesContainer } from '../../../containers/antecedentes';

import { MostrarAntecedentes } from './mostrar-antecedentes';
import { Antecedente, Paciente } from '../../../models';

const FormAntecedentes = () => {
  const [stateAntecedentes, setStateAntecedentes] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const antecedente = AntecedentesContainer.useContainer();
  const paciente = PacienteContainer.useContainer();

  const handleSubmit = (
    setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
    stateAntecedentes: object
  ) => async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    const pacienteId = (paciente.state as any).id as string;

    //infoToSave obtiene el arreglo de los antecedentes seleccionados por el usuario y pacienteId
    //reduce es una funcion que tiene dos parametros (acumulador y posActula) que filtra los antecedentes que tengan estado true para ser almacenados en el arreglo infoToSave
    //[] es el valor inicial de acumulador que reduce modificara en cada iteracion
    //acumulador.push agrega pacienteId y antecedente a infoToSave
    const infoToSave = Object.keys(stateAntecedentes).reduce((acumulador, posActual) => {
      if (stateAntecedentes[posActual]) {
        acumulador.push({
          pacienteId,
          antecedente: posActual,
        });
      }
      return acumulador;
    }, [] as Antecedente[]);

    await db.doCreateAntecedentes(infoToSave, pacienteId).then(() => {
      // Este es un contenedor que nos permitirá compartir los datos con otros
      // componentes de react
      antecedente.setState({ antecedentes: infoToSave });

      // mostrar resultado
      let plantamizaje = document.getElementById('plantamizaje');
      if (plantamizaje) {
        plantamizaje.style.display = '';
        plantamizaje.style.backgroundColor = '#f9f3f3';
      }
      setTimeout(() => {
        window.location.href = '#plantamizaje';
      }, 20);
    });
    await functions.sendEmail({ pacientId: pacienteId });
  };

  return (
    <div className="col-lg-12 pt-5 pb-3" style={{ padding: '0px 5px 10px 5px' }}>
      <form onSubmit={handleSubmit(setIsSubmitted, stateAntecedentes)}>
        <div className="col-lg-12 lighten-1" style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
          <div className="p-3 text-center wow zoomIn" data-wow-delay=".5s">
            <div className="h5 mt-3 text-left">ANTECEDENTES HEREDO-FAMILIARES</div>
            <p className="mt-3 text-justify">
              Seleccione si en su familia (papá, mamá, tíos, tías, hermanos, hermanas, hijos o hijas) se han presentado
              los siguientes tipos de cáncer:
            </p>
            {/*inicio check button*/}
            <div className="p-4 row" id="antecedenteshf">
              <MostrarAntecedentes
                name="AntecedentesHF"
                stateAntecedentes={stateAntecedentes}
                setStateAntecedentes={setStateAntecedentes}
                isSubmitted={isSubmitted}
              />
            </div>
            {/*fin check button*/}
          </div>
        </div>
        <hr className="hr-pink"></hr>
        <div className="col-lg-12 col-sm-12 lighten-1 ">
          <div className="p-3 text-center wow zoomIn" data-wow-delay=".1s">
            <div className="h5 mt-3 text-left">SÍNTOMAS</div>
            <p className="mt-3 text-justify">
              Seleccione si usted presenta (en este momento) alguno de los siguientes síntomas:
            </p>
            {/* inicio check button*/}
            <div className="p-4 row" id="sintomas">
              <MostrarAntecedentes
                name="Sintom"
                stateAntecedentes={stateAntecedentes}
                setStateAntecedentes={setStateAntecedentes}
                isSubmitted={isSubmitted}
                genero={(paciente.state as Paciente).genero}
              />
            </div>
            {/* fin check button*/}
          </div>
        </div>
        <hr className="hr-pink"></hr>
        <div
          className="col-lg-12 lighten-1"
          style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
        >
          <div className="p-3 text-center wow zoomIn" data-wow-delay=".1s">
            <div className="h5 mt-3 text-left">
              ANTECEDENTES PERSONALES PATOLÓGICOS Y ANTECEDENTES PERSONALES NO-PATOLÓGICOS
            </div>
            <p className="mt-3 text-justify">
              Seleccione si usted padece (en este momento) de alguna de las siguientes enfermedades y/o tiene alguno de
              los siguientes hábitos:
            </p>
            {/* inicio check button*/}
            <div className="p-4 row" id="AntecedentesAPPAPNP">
              <MostrarAntecedentes
                name="AntecedentesAPPAPNP"
                stateAntecedentes={stateAntecedentes}
                setStateAntecedentes={setStateAntecedentes}
                isSubmitted={isSubmitted}
                genero={(paciente.state as Paciente).genero}
              />
            </div>
            {/* fin check button*/}
          </div>
        </div>
        <div className="col-lg-12 flex-center">
          {!isSubmitted && (
            <button className="btn btn-primary" type="submit">
              <i className="fa fa-paper-plane-o mr-2"></i>Guardar
            </button>
          )}
          {isSubmitted && (
            <button
              className="btn btn-primary"
              onClick={() => {
                setIsSubmitted(false);
              }}
            >
              <i className="fa fa-paper-plane-o mr-2"></i>Editar
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormAntecedentes;
