import React from 'react';
import ReactDOM from 'react-dom';
import { Login } from './components/login';
import { Main } from './components/main';
import { firebase } from './firebase';

import './styles/main.css';

if (sessionStorage.getItem('loggedIn') !== 'TamizajeOncologico2021') {
  ReactDOM.render(<Login />, document.body);
} else {
  firebase.db.enablePersistence();

  ReactDOM.render(<Main />, document.getElementById('main'));
}
document.getElementById('loadingModal')?.remove();
