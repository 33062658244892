import { Genero } from './paciente';

export interface Antecedente {
  pacienteId: string;
  antecedente: string;
}

export const AntecedentesHF = {
  AHF_2_01: 'Cáncer de próstata',
  AHF_2_02: 'Cáncer de testículo',
  AHF_2_03: 'Cáncer de estómago',
  AHF_2_04: 'Cáncer de endometrio',
  AHF_2_05: 'Cáncer de ovario',
  AHF_2_06: 'Cáncer colon',
  AHF_2_07: 'Cáncer de pulmón',
  AHF_2_08: 'Cáncer de mama',
  AHF_2_09: 'Cáncer páncreas',
  AHF_2_10: 'Cáncer de peritoneo',
  AHF_2_11: 'Cáncer de trompas de Falopio',
};

export const AntecedentesAPPAPNP = {
  APPAPNP_3_01: 'Cáncer en un testículo',
  APPAPNP_3_02: 'Criptorquídea',
  APPAPNP_3_03: 'Atrofia en uno o ambos testículos',
  APPAPNP_3_04: 'Hipospadia',
  APPAPNP_3_05: 'Resultados positivos para el gen BRCA1 y BRCA2',
  APPAPNP_3_06: 'Síndrome de Lynch',
  APPAPNP_3_07: 'Síndrome de Peutz-Jeghers',
  APPAPNP_3_08: 'Diagnóstico de poliposis adenomatosa',
  APPAPNP_3_09: 'Diagnóstico de adenoma gástrico',
  APPAPNP_3_10: 'Diagnóstico de metaplasia intestinal',
  APPAPNP_3_11: 'Síndrome poliposo juvenil',
  APPAPNP_3_12: 'Ha sido diagnosticado con una infección por helicobacter pylori',
  APPAPNP_3_13: 'Sangrado digestivo',
  APPAPNP_3_14: 'Obesidad',
  APPAPNP_3_15: 'Tabaquismo',
  APPAPNP_3_16: 'Diagnóstico de enfermedad intestinal inflamatoria',
  APPAPNP_3_17: 'Acromegalia',
  APPAPNP_3_18: 'Colecistectomía',
  APPAPNP_3_19: 'Terapia androgénica',
  APPAPNP_3_20: 'Diabetes',
  APPAPNP_3_21: 'Etilismo (consumo importante de alcohol)',
  APPAPNP_3_22: 'Fibrosis pulmonar',
  APPAPNP_3_23: 'Ha recibido radioterapia en el tórax',
  APPAPNP_3_24: 'En su trabajo se expone a metales pesados',
  APPAPNP_3_25: 'Se encuentra en inmunosupresión',
  APPAPNP_3_26: 'Tiene implantes mamarios',
  APPAPNP_3_27: 'Inicio de la menstruación antes de los 12 años',
  APPAPNP_3_28: 'Nulipara (no ha tenido ningún embarazo)',
  APPAPNP_3_29: 'Primer parto después de los 30 años',
  APPAPNP_3_30: 'Uso prolongado de anticonceptivos orales',
  APPAPNP_3_31: 'Inicio de relaciones sexuales antes de los 21 años',
};

export const AntecedentesAPPAPNPGenero = {
  APPAPNP_3_01: Genero.GEN_1_1,
  APPAPNP_3_02: Genero.GEN_1_1,
  APPAPNP_3_03: Genero.GEN_1_1,
  APPAPNP_3_04: Genero.GEN_1_1,
  APPAPNP_3_26: Genero.GEN_1_2,
  APPAPNP_3_27: Genero.GEN_1_2,
  APPAPNP_3_28: Genero.GEN_1_2,
  APPAPNP_3_29: Genero.GEN_1_2,
  APPAPNP_3_30: Genero.GEN_1_2,
  APPAPNP_3_31: Genero.GEN_1_2,
};
