import React from 'react';

export const Body = ({ children }) => {
  return (
    <div
      style={{
        border: '1px solid black',
        display: 'flex',
        margin: '20px',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          borderBottom: '1px solid black',
          padding: '10px',
        }}
      >
        <h4>EL SIGUIENTE DOCUMENTO NO SUSTITUYE Y/O ANULA LA OPINIÓN MÉDICA</h4>
      </div>
      <div
        style={{
          padding: '20px',
        }}
      >
        {children}
      </div>
    </div>
  );
};
