import { FIREBASE_COLL_ANTECEDENTES, FIREBASE_COLL_PACIENTES } from './collections';
import { db } from './firebase';

// Paciente API
export const doCreatePaciente = (pacienteData) => {
  return db
    .collection(FIREBASE_COLL_PACIENTES)
    .add(pacienteData)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export const doUpdatePaciente = (pacienteData) => {
  return db
    .collection(FIREBASE_COLL_PACIENTES)
    .doc(pacienteData.id)
    .update(pacienteData)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export const doDeleteAntecedentes = (pacienteId) => {
  return db
    .collection(FIREBASE_COLL_ANTECEDENTES)
    .where('pacienteId', '==', pacienteId)
    .get()
    .then((paciente) => {
      if (paciente.empty) {
        return;
      }
      const batch = db.batch();
      paciente.forEach((pac) => {
        batch.delete(pac.ref);
      });

      return batch
        .commit()
        .then(function () {
          console.log('done');
        })
        .catch((err) => {
          console.log('no se envió', err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};
// Antecedentes API
export const doCreateAntecedentes = (antecedentesData, pacienteId) => {
  return doDeleteAntecedentes(pacienteId).then(() => {
    const batch = db.batch();

    antecedentesData.forEach((antecedente) => {
      const antecedentesRef = db.collection(FIREBASE_COLL_ANTECEDENTES).doc();
      batch.set(antecedentesRef, antecedente);
    });

    return batch
      .commit()
      .then(function () {
        console.log('done');
      })
      .catch((err) => {
        console.log('no se envió', err);
      });
  });
};
