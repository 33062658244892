import React from 'react';

export const Header = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '20px',
      }}
    >
      <img
        style={{ height: 'auto', width: '300px' }}
        className="img-fluid rounded"
        src="img/Logo-centro.png"
        alt="Centro Oncológico Costarricense"
      />
      <h2 style={{ textAlign: 'right', color: '#6d774a' }}>
        MI PLAN DE <br />
        DETECCIÓN TEMPRANA
      </h2>
    </div>
  );
};
