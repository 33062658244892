import firebase from 'firebase';
import 'firebase/functions';

if (process.env.NODE_ENV === 'development') {
  firebase.functions().useEmulator('localhost', 5001);
}

export interface EmailSendFunctionParams {
  pacientId: string;
}

export interface EmailSendFunctionResponse {}

const sendEmailFunction = firebase.functions().httpsCallable('sendEmail');

export const sendEmail = async (data: EmailSendFunctionParams): Promise<EmailSendFunctionResponse> => {
  return await sendEmailFunction(data);
};
