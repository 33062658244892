import { format } from 'date-fns';
import React from 'react';
import { PacienteContainer } from '../../../containers/paciente';
import { Paciente } from '../../../models';

export const PacienteInfo = () => {
  const paciente = PacienteContainer.useContainer();

  if (paciente.state && Object.keys(paciente.state).length !== 0) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span>
            NOMBRE: <b>{(paciente.state as Paciente).nombre1}</b>
          </span>
          <span>
            PRIMER APELLIDO: <b>{(paciente.state as Paciente).apellido1}</b>
          </span>
          <span>
            SEGUNDO APELLIDO: <b>{(paciente.state as Paciente).apellido2}</b>
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span>
            NÚMERO DE IDENTIFICACIÓN: <b>{(paciente.state as Paciente).ident}</b>
          </span>
          <span>
            GÉNERO: <b>{(paciente.state as Paciente).genero}</b>
          </span>
          <span>
            FECHA DE NACIMIENTO: <b>{format((paciente.state as Paciente).fechaNacimiento, 'dd/MM/yyyy')}</b>
          </span>
        </div>
      </>
    );
  } else {
    return null;
  }
};
