import React from 'react';
import { Header } from './header';
import { Body } from './body';
import { Footer } from './footer';
import { PacienteInfo } from './paciente-info';
import { AntecedentesInfo } from './antecendentes-info';
import { TamizajesInfo } from './tamizajes-info';

export const PdfSection = () => {
  return (
    <>
      <Header />
      <Body>
        <PacienteInfo />
        <div
          style={{
            marginTop: '3em',
            marginBottom: '2em',
          }}
        >
          HA SELECCIONADO EN LA HERRAMIENTA LOS SIGUIENTES ASPECTOS:
        </div>
        <AntecedentesInfo />
        <div
          style={{
            marginTop: '3em',
            marginBottom: '2em',
          }}
        >
          DE ACUERDO A LO ANTERIOR Y BASADOS EN LAS DIFERENTES GUÍAS NACIONALES E INTERNACIONALES SOBRE LAS QUE ESTA
          HERRAMIENTA SE RIGE SE HAN OBTENIDO LAS SIGUIENTES RECOMENDACIONES:
        </div>
        <TamizajesInfo />
        <div
          style={{
            backgroundColor: '#d8d8d8',
            margin: '2em 100px 0',
            padding: '20px',
            textAlign: 'center',
            border: '1px solid #d8d8d8',
          }}
        >
          EL CENTRO ONCOLÓGICO COSTARRICENSE HA CREADO ESTA HERRAMIENTA COMO FINALIDAD DE MEJORAR LAS POSIBILIDADES DE
          SUPERVIVIENCIA DE LAS PERSONAS CON CÁNCER, CREANDO CONSICENCIA SOBRE LA IMPORTANCIA DE CHEQUEOS PERIÓDICOS
          ACORDES A SUS ANTECEDENTES PERSONALES Y FAMILIARES.
          <br />
          <br />
          EL DIAGNÓSTICO Y TRATAMIENTO, LO MÁS TEMPRANO POSIBLE, PUEDEN SALVAR MUCHAS VIDAS.
        </div>
      </Body>
      <Footer />
    </>
  );
};
