import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: 'auto',
    height: '90%',
    width: '90%',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 3),
  },
}));

export const CondicionesModal = () => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  return (
    <div style={{ display: 'inline' }}>
      <a href="#open" onClick={handleOpen} style={{ display: 'inline', color: 'blue' }}>
        Leer términos y condiciones.
      </a>
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="row no-gutters">
            <div className="col-lg-12" style={{ textAlign: 'right' }}>
              <a href="#close" onClick={handleClose} color="primary">
                <i className="fa fa-close"></i>
              </a>
            </div>
            <div className="col-lg-12">
              <div className="wow">
                <img
                  className="img-fluid rounded z-depth-2 white"
                  src="img/Logo-centro.png"
                  alt="Centro Oncológico Costarricense"
                />
              </div>
              <div className="row wow">
                <div className="col-lg-12 p-3">
                  <p className="h4 text-center">Términos y Condiciones</p>
                  <div id="contents">
                    <p className="c0">
                      <span className="c4">TÉRMINOS Y CONDICIONES DE USO</span>
                    </p>
                    <p className="c0">
                      <span className="c4">MI PLAN DE DETECCIÓN TEMPRANA</span>
                    </p>
                    <p className="c0">
                      <span className="c4">CENTRO ONCOLÓGICO COSTARRICENSE</span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">Actualizado el 12 de julio del 2020</span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        El presente documento pretende detallar los usos del sitio web, el sitio se permite la
                        aplicación de condiciones, restricciones y políticas de privacidad adicionales.
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Descargo de responsabilidad de atención médica</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        El contenido, los productos y los servicios que se ofrecen aquí están aquí para educar a los
                        consumidores sobre la atención médica y los problemas médicos que pueden afectar su vida diaria.
                        Nada en el contenido, productos o servicios debe considerarse o utilizarse como un sustituto de
                        un consejo médico, diagnóstico o tratamiento. Este sitio y sus servicios no constituyen la
                        práctica de ningún consejo, diagnóstico o tratamiento médico, de enfermería u otro profesional
                        de la salud. Siempre debe hablar con su proveedor de atención médica para el diagnóstico y el
                        tratamiento, incluidas sus necesidades médicas específicas. Ninguno de los productos o servicios
                        ofrecidos a través de este sitio web representa o garantiza que ningún servicio o producto en
                        particular sea seguro, apropiado o efectivo para usted. Si tiene o sospecha que tiene un
                        problema o condición médica, comuníquese de inmediato con un profesional de la salud calificado.
                        Si tiene una emergencia médica, llame al 911 o solicite ayuda médica de emergencia al teléfono
                        más cercano.
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Términos de Uso</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        Usted acepta revisar el acuerdo periódicamente para estar al tanto de dichas modificaciones, y
                        su acceso o uso del servicio constituye su aceptación del acuerdo tal como aparece en el momento
                        de su acceso o uso.{' '}
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">Destinado a usuarios mayores de 18 años.</span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">Usted acepta no:</span>
                    </p>
                    <ul className="c5 start">
                      <li className="c0 c3">
                        <span className="c1">
                          Cargar o transmitir cualquier comunicación o contenido de cualquier tipo que pueda infringir o
                          violar los derechos de cualquier parte.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Utilice este sitio web para cualquier propósito que viole las leyes locales, estatales,
                          nacionales o internacionales.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Utilice este sitio como un medio para distribuir publicidad u otro material no solicitado a
                          terceros.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Use este sitio web para publicar o transmitir material que sea ilegal, obsceno, difamatorio,
                          amenazante, acosador, abusivo, difamatorio, odioso o vergonzoso a cualquier otra persona o
                          entidad.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Intente deshabilitar, &quot;piratear&quot; o interferir con el buen funcionamiento de este
                          sitio web.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Si utiliza alguna parte del Sitio que requiera acceso seguro, es responsable de mantener la
                          confidencialidad de su cuenta y contraseña y de restringir el acceso a su computadora, y
                          acepta aceptar la responsabilidad de todas las actividades que ocurran bajo su cuenta o
                          contraseña.
                        </span>
                      </li>
                    </ul>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Limitaciones de orden</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        Nos reservamos el derecho de rechazar cualquier pedido que realice con nosotros, o de limitar
                        las cantidades en cualquier pedido, sin dar ninguna razón. Si rechazamos su pedido, generalmente
                        intentaremos notificarlo utilizando la dirección de correo electrónico que nos proporcionó
                        cuando realizó el pedido.
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Terminación de uso</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        El Centro Oncológico Costarricense, a su exclusivo criterio, cancelar su cuenta o su uso del
                        Sitio en cualquier momento.{' '}
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">Si publica información en el Sitio, acepta que no:</span>
                    </p>
                    <ul className="c5 start">
                      <li className="c0 c3">
                        <span className="c1">
                          Publicar material que infrinja los derechos de terceros, incluidos los derechos de propiedad
                          intelectual, privacidad o publicidad.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Publique material que sea ilegal, obsceno, difamatorio, amenazante, acosador, abusivo,
                          difamatorio, odioso o vergonzoso para cualquier otra persona o entidad.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">Publicar anuncios de solicitudes de negocios.</span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Hacerse pasar por otra persona, incluidos, entre otros, un cónyuge, hijo o hermano.
                        </span>
                      </li>
                    </ul>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">El sitio se reserva los derechos de:</span>
                    </p>
                    <ul className="c5 start">
                      <li className="c0 c3">
                        <span className="c1">
                          Eliminar las comunicaciones que no cumplan con estos términos de uso.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Termine el acceso de un usuario a todo el Sitio o características específicas como el blog.
                        </span>
                      </li>
                    </ul>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Indemnización</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        Usted acepta que indemnizará al Centro Oncológico Costarricense por los daños, pérdidas,
                        responsabilidades, juicios, costos o gastos (incluidos los honorarios y costos razonables de
                        abogados) que surjan de un reclamo de un tercero relacionado con los materiales que ha publicado
                        u otras acciones tomadas por usted en el sitio.
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Propiedad intelectual</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        El contenido y el diseño del sitio www.detecciontemprana.com está protegido por las leyes de
                        propiedad intelectual nacional. No puede copiar, reproducir, volver a publicar, cargar,
                        publicar, mostrar, transmitir o enmarcar ninguno de estos materiales sin el consentimiento
                        previo por escrito de los propietarios de los derechos de autor, excepto que puede ver,
                        descargar, mostrar e imprimir una sola copia de estos materiales en un computadora individual
                        para uso personal y no comercial únicamente, siempre y cuando: no altere ni modifique los
                        materiales de ninguna manera; incluye todos los avisos y exenciones de responsabilidad
                        aplicables (incluidos los avisos de derechos de autor). Usted comprende y acepta que el título
                        de estos materiales no pasará a usted ni a ningún otro usuario.
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Renuncias.</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        Cualquier servicio, contenido o información contenida o proporcionada por el sitio se
                        proporciona &quot;tal cual&quot;. No se ofrece ninguna garantía expresa o implícita,
                        representaciones o respaldos de ningún tipo (incluyendo sin limitación las garantías de título o
                        no infracción, o las garantías implícitas de comerciabilidad o idoneidad para un servicio
                        público con un pedido de servicio o mismo pedido). No garantiza que el uso del sitio estará
                        libre de dificultades tecnológicas, incluyendo, pero sin limitarse a, la no disponibilidad de
                        información, tiempo de tiempo, disrupciones de servicio, virus o virus, y usted entiende que es
                        responsable de la implementación de su aplicación procedimientos y puntos de control para
                        satisfacer sus requisitos particulares para la exactitud de la entrada y salida de datos. Los
                        errores se corregirán cuando se descubran.{' '}
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Limitaciones de responsabilidad.</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        El uso del sitio es bajo su propio riesgo, y en ningún caso, será responsable de cualquier daño
                        directo, indirecto, incidental, consecuente, especial, ejemplar, punitivo o cualquier otro daño
                        monetario u otro, honorarios, multas, penalizaciones , o responsabilidades derivadas o
                        relacionadas de cualquier forma con este servicio, o sitios accedidos a través de este servicio,
                        y/o contenido o información proporcionada aquí. Su único y exclusivo remedio por insatisfacción
                        con el servicio es dejar de usar el servicio. El usuario reconoce que este párrafo se aplicará a
                        todos los contenidos, mercancías y servicios disponibles a través del sitio.{' '}
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Información del contacto.</span>
                    </p>
                    <p className="c0">
                      <span>Puede comunicarse con nosotros a: </span>
                      <span className="c8">
                        <a className="c6" href="mailto:gimena.chavarria@centrooncologico.cr">
                          gimena.chavarria@centrooncologico.cr
                        </a>
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">POLÍTICA DE PRIVACIDAD</span>
                    </p>
                    <p className="c0">
                      <span className="c4">MI PLAN DE DETECCIÓN TEMPRANA</span>
                    </p>
                    <p className="c0">
                      <span className="c4">CENTRO ONCOLÓGICO COSTARRICENSE</span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">Política de privacidad web.</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        Su privacidad es muy importante para nosotros, queremos que conozca los detalles de cómo
                        recopilamos, usamos, compartimos y protegemos su información.{' '}
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">¿Qué información recolectamos?</span>
                    </p>
                    <ul className="c5 start">
                      <li className="c0 c3">
                        <span className="c1">
                          Información que nos brinda: Recibimos y almacenamos información que usted ingresa en nuestros
                          sitios o nos brinda de cualquier otra manera, como nombre, dirección de correo electrónico y
                          número de teléfono. Esto incluye la información que envíe en los formularios, como los
                          formularios de solicitud de cita. Algunos formularios recopilan información confidencial, como
                          información de salud, necesaria para que podamos brindarle nuestros servicios.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Información que recopilamos automáticamente: cuando interactúa con nuestros sitios, se
                          recopila automáticamente cierta información sobre su uso de nuestros sitios. Esta información
                          incluye información de la computadora y la conexión, como estadísticas de las visitas a su
                          página, tráfico hacia y desde nuestros sitios, URL de referencia, datos de anuncios, su
                          dirección IP e identificadores del dispositivo. Esta información también puede incluir su
                          historial de transacciones y su información de registro web, cómo busca nuestros sitios, los
                          sitios web en los que hace clic desde nuestros sitios o correos electrónicos, si abre nuestros
                          correos electrónicos, y sus actividades de navegación en otros sitios web.
                        </span>
                      </li>
                      <li className="c0 c3">
                        <span className="c1">
                          Gran parte de esta información se recopila a través de cookies, balizas web y otras
                          tecnologías de seguimiento, así como a través de su navegador web o dispositivo (por ejemplo,
                          dirección IP, dirección MAC, versión del navegador, etc.).
                        </span>
                      </li>
                    </ul>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c4">
                        Comunicaciones por correo electrónico, boletín y servicios relacionados.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        <a
                          className="c6"
                          href="https://www.google.com/url?q=http://www.detecciontemprana.com&amp;sa=D&amp;ust=1594622559489000&amp;usg=AOvVaw33J4Cd_H2erJ2l1ZFis1yQ"
                        >
                          www.detecciontemprana.com
                        </a>
                      </span>
                      <span>
                        &nbsp;le brinda la oportunidad de recibir comunicaciones nuestras o de terceros. Puede cancelar
                        su suscripción en cualquier momento comuncándose a:{' '}
                      </span>
                      <span className="c8">
                        <a className="c6" href="mailto:info@centrooncologico.cr">
                          info@centrooncologico.cr
                        </a>
                      </span>
                      <span className="c1">&nbsp;</span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        Las comunicaciones por correo electrónico que nos envíe a través de los enlaces de correo
                        electrónico en nuestro sitio pueden compartirse con un representante de servicio al cliente,
                        empleado, experto médico o agente que sea más capaz de atender su consulta. Hacemos todo lo
                        posible para responder de manera oportuna una vez que se reciben las comunicaciones. Una vez que
                        hayamos respondido a su comunicación, se descartará o archivará, según la naturaleza de la
                        consulta.
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        La funcionalidad de correo electrónico en nuestro sitio no proporciona un medio de comunicación
                        completamente seguro y confidencial. Es posible que otro usuario de Internet pueda acceder o ver
                        su comunicación por correo electrónico mientras está en tránsito hacia nosotros. Si desea
                        mantener su comunicación privada, no use nuestro correo electrónico.
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1"></span>
                    </p>
                    <p className="c0 c2" id="h.gjdgxs">
                      <span className="c1"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaterialModal>
    </div>
  );
};
