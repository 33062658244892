import React from 'react';

import { Genero, Paciente, RangoEdades } from './paciente';

export const Tz0601 = {
  id: '6.1',
  descripcion: () => (
    <span>
      Se le recomienda realizar un PSA (antígeno prostático específico) y consultar con el médico especialista en
      urología.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_1],
      rangosEdades: [
        RangoEdades.RANEDA_5_01,
        RangoEdades.RANEDA_5_02,
        RangoEdades.RANEDA_5_03,
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
      ],
      sintomas: ['SINT_4_01', 'SINT_4_02', 'SINT_4_03', 'SINT_4_04', 'SINT_4_05'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (antecedentes.length < 2) {
      return false;
    }
    let contador = 0;
    Tz0601.gruposTamizaje[0].sintomas.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    return contador >= 2;
  },
};

export const Tz0602 = {
  // Requiere validacion en funcion de tamizaje
  id: '6.2',
  descripcion: () => (
    <span>
      Se le recomienda realizar un ultrasonido de testículo. (*) De ser necesario según la recomendación del médico
      complementar el estudio con TAC o resonancia magnética.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_1],
      sintomas: ['SINT_4_06', 'SINT_4_07', 'SINT_4_08', 'SINT_4_09'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    let contador = 0;
    Tz0602.gruposTamizaje[0].sintomas.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    return contador > 0;
  },
};

export const Tz0603 = {
  // Requiere validacion en funcion de tamizaje
  // los sintomas y antecedentes deben ser >=2
  id: '6.3',
  descripcion: () => (
    <span>
      Se le recomienda realizar una radiografía de tórax PA y lateral y consultar con un médico especialista en
      neumología o cirugía de tórax.
    </span>
  ),
  gruposTamizaje: [
    {
      sintomas: ['SINT_4_13', 'SINT_4_14', 'SINT_4_15', 'SINT_4_16'],
      aAPPAPNP: [
        'APPAPNP_3_15', // obligatorio
        'APPAPNP_3_21',
        'APPAPNP_3_22',
        'APPAPNP_3_23',
        'APPAPNP_3_24',
        'APPAPNP_3_25',
      ],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    if (!antecedentes.includes('APPAPNP_3_15')) {
      return false;
    }
    let contador = 0;
    Tz0603.gruposTamizaje[0].sintomas.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    Tz0603.gruposTamizaje[0].aAPPAPNP.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    return contador >= 2;
  },
};

export const Tz0604 = {
  id: '6.4',
  descripcion: () => (
    <span>
      Se le recomienda realizar la prueba de COTEST (VPH+papanicolau) o bien solamente PAP. Si el resultado de este es
      negativo se debe de continuar con el tamizaje normalmente, si el resultado es positivo se debe de consultar con el
      médico especialista.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      aAPPAPNP: ['APPAPNP_3_25'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    if (!antecedentes.includes('APPAPNP_3_25')) {
      return false;
    }
    return true;
  },
};

export const Tz0605 = {
  id: '6.5',
  descripcion: () => (
    <span>
      Se le recomienda realizar un estudio de ultrasonido y complementarlo con una mamografía. Si la paciente lo desea,
      puede sustituir estos estudios por una resonancia magnética de mama.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_01,
        RangoEdades.RANEDA_5_02,
        RangoEdades.RANEDA_5_03,
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
      ],
      sintomas: ['SINT_4_17', 'SINT_4_18', 'SINT_4_19', 'SINT_4_20', 'SINT_4_21'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (antecedentes.length < 2) {
      return false;
    }
    let contador = 0;
    Tz0605.gruposTamizaje[0].sintomas.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    return contador > 0;
  },
};

export const Tz0606 = {
  id: '6.6',
  descripcion: () => (
    <span>
      Se le recomienda realizar un PAP un año posterior al inicio de las relaciones y continuar con manejo normal.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [RangoEdades.RANEDA_5_01],
      aAPPAPNP: ['APPAPNP_3_31'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    if (!antecedentes.includes('APPAPNP_3_31')) {
      return false;
    }
    return true;
  },
};

export const Tz0607 = {
  id: '6.7',
  descripcion: () => (
    <span>
      Se recomienda realizar el tamizaje según corresponda para la edad, si los implantes son de silicona es importante
      aclarar que el control se debe de realizar por medio de resonancia magnética de mama.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      aAPPAPNP: ['APPAPNP_3_26'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    if (!antecedentes.includes('APPAPNP_3_26')) {
      return false;
    }
    return true;
  },
};

export const Tz0608 = {
  id: '6.8',
  descripcion: () => (
    <span>
      Se le recomienda inciar colonoscopía cada diez años y continuar control o seguimiento según criterio médico.
    </span>
  ),
  gruposTamizaje: [
    {
      rangosEdades: [
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
        RangoEdades.RANEDA_5_11,
        RangoEdades.RANEDA_5_12,
        RangoEdades.RANEDA_5_13,
      ],
    },
  ],
  esAplicable: () => {
    return true;
  },
};

export const Tz0609 = {
  id: '6.9',
  descripcion: () => (
    <span>
      Se le recomienda realizar un PSA (antígeno prostático específico) y complementar con tacto rectal cada cuatro
      años. Si el paciente lo desea, puede sustituir estos estudios por una resonancia magnética multiparamétrica de
      próstata.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_1],
      rangosEdades: [RangoEdades.RANEDA_5_06, RangoEdades.RANEDA_5_07],
      antecedentesHF: ['AHF_2_01'],
    },
    {
      generos: [Genero.GEN_1_1],
      rangosEdades: [RangoEdades.RANEDA_5_06, RangoEdades.RANEDA_5_07],
      aAPPAPNP: ['APPAPNP_3_05'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    if (!antecedentes.includes('AHF_2_01') && !antecedentes.includes('APPAPNP_3_05')) {
      return false;
    }
    return true;
  },
};

export const Tz0610 = {
  id: '6.10',
  descripcion: () => <span>Se le recomienda realizar un PAP (papanicolau) cada tres años.</span>,
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_02,
        RangoEdades.RANEDA_5_03,
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
      ],
    },
  ],
  esAplicable: () => {
    return true;
  },
};

export const Tz0611 = {
  id: '6.11',
  descripcion: () => (
    <div>
      <span>
        Existen varias pruebas para llevar un control. A continuación se detallan las diferentes opciones de estudios,
        estos estudios se elijen según la preferencia del paciente.
      </span>
      <ul>
        <li>Opción 1: realizar un PAP (papanicolau) cada tres años.</li>
        <li>
          Opción 2: realizar una prueba de VPH (virus de papiloma humano) y complementar con un PAP (papanicolau) cada
          cinco años.
        </li>
        <li>Opción 3: realizar una prueba de VPH (virus de papiloma humano) cada cuatro años.</li>
      </ul>
    </div>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
      ],
    },
  ],
  esAplicable: () => {
    return true;
  },
};

export const Tz0612 = {
  id: '6.12',
  descripcion: () => (
    <div>
      <span>
        Si la paciente así lo desea, a partir de los 65 años se pueden suspender los tamizajes para cáncer de cérvix.
        También puede suspenderse, en caso de que la paciente considere que no va a tener prácticas sexuales de riesgo
        (es importante recordar que se consideran prácticas sexuales de no riesgo cuando se mantiene una sola pareja
        sexual por ambas partes):
      </span>
      <ul>
        <li>
          Opción 1: En los últimos diez años (con la última muestra de hace cinco años), los resultados del COTEST
          (papanicolau y prueba del virus del papiloma humano) arrojen resultados negativos).
        </li>
        <li>
          Opción 2: En los últimos diez años (con la última muestra hace tres años), los resultados del PAP
          (papanicolau) arrojen resultados negativos.
        </li>
      </ul>
    </div>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
      ],
    },
  ],
  esAplicable: () => {
    return true;
  },
};

export const Tz0613 = {
  id: '6.13',
  descripcion: () => (
    <span>
      Si usted así lo desea, puede comenzar su tamizaje para cáncer de mama realizándose una resonancia magnética de
      mama o mamografía cada dos años.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [RangoEdades.RANEDA_5_06, RangoEdades.RANEDA_5_07],
    },
  ],
  esAplicable: () => {
    return true;
  },
};

export const Tz0614 = {
  id: '6.14',
  descripcion: () => (
    <div>
      <p>Se le recomienda realizar una resonancia magnética de mama o mamografía cada dos años.</p>

      <p>
        <strong>Nota de Aclaración:</strong> Si la paciente tiene implantes mamarios de silicón, el tamizaje se debe de
        realizar específicamente con resonancia magnética de mama.
      </p>
    </div>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [RangoEdades.RANEDA_5_06, RangoEdades.RANEDA_5_07],
      sintomas: ['SINT_4_17', 'SINT_4_18', 'SINT_4_19', 'SINT_4_20', 'SINT_4_21'],
      antecedentesHF: ['AHF_2_01', 'AHF_2_08', 'AHF_2_10'],
      antecedentesAPPAPNP: ['APPAPNP_3_05'],
    },
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
        RangoEdades.RANEDA_5_11,
        RangoEdades.RANEDA_5_12,
      ],
    },
  ],
  esAplicable: (antecedentes: any, paciente: Paciente) => {
    if (Tz0614.gruposTamizaje[1].rangosEdades.includes(paciente.edad)) {
      return true;
    }
    let contador = 0;
    let sintomas = Tz0614.gruposTamizaje[0].sintomas;
    let antecedentesHF = Tz0614.gruposTamizaje[0].antecedentesHF;
    let antecedentesAPPAPNP = Tz0614.gruposTamizaje[0].antecedentesAPPAPNP;

    sintomas &&
      sintomas.forEach((sintoma) => {
        if (antecedentes.includes(sintoma)) {
          contador++;
        }
      });

    antecedentesHF &&
      antecedentesHF.forEach((sintoma) => {
        if (antecedentes.includes(sintoma)) {
          contador++;
        }
      });
    antecedentesAPPAPNP &&
      antecedentesAPPAPNP.forEach((sintoma) => {
        if (antecedentes.includes(sintoma)) {
          contador++;
        }
      });
    return contador > 0;
  },
};

export const Tz0616 = {
  id: '6.16',
  descripcion: () => (
    <span>
      Debido a que usted marcó dos o más antecedentes heredofamiliares de riesgo, se le recomienda realizar los exámenes
      genéticos BRCA1 y BRCA2.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_01,
        RangoEdades.RANEDA_5_02,
        RangoEdades.RANEDA_5_03,
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
      ],
      antecedentesHF: [
        // seleccionar >= 2 opciones
        'AHF_2_01',
        'AHF_2_05',
        'AHF_2_08',
        'AHF_2_09',
        'AHF_2_10',
        'AHF_2_11',
      ],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    let contador = 0;
    Tz0616.gruposTamizaje[0].antecedentesHF.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    return contador >= 2;
  },
};

// NO ESTA EN LOS DIAGRAMAS DIBUJADOS A MANO
//   id = '6.17';
/* Debido a que usted marcó antecedes heredofamiliares de cáncer de endometrio, gástrico y ovario, se le recomienda realizar estudios por Síndrome de Lynch. El Síndrome de Lynch debe ser evaluado por un gastroenterólogo.
 */

export const Tz0618 = {
  id: '6.18',
  descripcion: () => (
    <span>
      Debido a que usted marcó antecedente heredofamiliar de cáncer gástrico, se le recomienda realizarse una
      gastroscopía cada dos años.
    </span>
  ),
  gruposTamizaje: [
    {
      rangosEdades: [
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
        RangoEdades.RANEDA_5_11,
        RangoEdades.RANEDA_5_12,
      ],
      antecedentesHF: ['AHF_2_03'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('AHF_2_03');
  },
};

export const Tz0619 = {
  id: '6.19.00',
  descripcion: () => (
    <span>
      Si usted marcó el Síndrome de Peutz-Jeghers, se le recomienda realizar una gastroscopia y una colonoscopía, más
      cápsula endoscópica. Según las guías el primera control se debe de realizar a los 8 años de edad, en caso de que
      el resultado sea negativo a partir de los 18 años se recomienda realizar el mismo control cada año.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_1],
      aAPPAPNP: ['APPAPNP_3_07'],
      rangosEdades: [
        RangoEdades.RANEDA_5_01,
        RangoEdades.RANEDA_5_02,
        RangoEdades.RANEDA_5_03,
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
        RangoEdades.RANEDA_5_11,
        RangoEdades.RANEDA_5_12,
      ],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_07');
  },
};

export const Tz061901 = {
  id: '6.19.01',
  descripcion: () => (
    <span>
      Si usted marcó el Síndrome de Peutz-Jeghers, se le recomienda realizar una gastroscopia y una colonoscopía, más
      cápsula endoscópica. Además, a las mujeres se les recomienda realizar un ultrasonido ginecológico cada año.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [RangoEdades.RANEDA_5_01, RangoEdades.RANEDA_5_02],
      aAPPAPNP: ['APPAPNP_3_07'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_07');
  },
};

export const Tz061902 = {
  id: '6.19.02',
  descripcion: () => (
    <span>
      Si usted marcó el Síndrome de Peutz-Jeghers, se le recomienda realizar una gastroscopia y una colonoscopía, más
      cápsula endoscópica. Además, a las mujeres se les recomienda realizar un ultrasonido ginecológico y una mamografía
      cada año.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_03,
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
        RangoEdades.RANEDA_5_11,
        RangoEdades.RANEDA_5_12,
      ],
      aAPPAPNP: ['APPAPNP_3_07'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_07');
  },
};

export const Tz0620 = {
  id: '6.20.00',
  descripcion: () => (
    <span>Debido a que usted marcó Síndrome Lynch, se le recomienda realizar una colonoscopía cada año.</span>
  ),
  gruposTamizaje: [
    {
      rangosEdades: [RangoEdades.RANEDA_5_03],
      aAPPAPNP: ['APPAPNP_3_06'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_06');
  },
};

export const Tz062001 = {
  id: '6.20.01',
  descripcion: () => (
    <span>
      Debido a que usted marcó Síndrome Lynch, se le recomienda realizar una colonoscopía cada año, una gastroscopía
      cada tres años y un examen general de orina cada año.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_1],
      rangosEdades: [RangoEdades.RANEDA_5_04, RangoEdades.RANEDA_5_12],
      aAPPAPNP: ['APPAPNP_3_06'],
    },
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [RangoEdades.RANEDA_5_04, RangoEdades.RANEDA_5_05],
      aAPPAPNP: ['APPAPNP_3_06'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_06');
  },
};

export const Tz062002 = {
  id: '6.20.02',
  descripcion: () => (
    <span>
      Debido a que usted marcó Síndrome Lynch, se le recomienda realizar una colonoscopía cada año, una gastroscopía
      cada tres años y un examen general de orina cada año. Además, se recomienda realizar un ultrasonido ginecológico
      cada cinco años.
    </span>
  ),
  gruposTamizaje: [
    {
      generos: [Genero.GEN_1_2],
      rangosEdades: [
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
        RangoEdades.RANEDA_5_11,
        RangoEdades.RANEDA_5_12,
      ],
      aAPPAPNP: ['APPAPNP_3_06'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_06');
  },
};

// export const Tz0621 = {
//   id: '6.21.00',
//   descripcion: () => (
//     <span>
//       Debido a que usted marcó Poliposis Adenomatosos, se le recomienda realizar una colonoscopía cada año a partir de
//       los 12 años de edad.
//     </span>
//   ),
//   gruposTamizaje: [
//     {
//       aAPPAPNP: ['APPAPNP_3_08'],
//     },
//   ],
//   esAplicable: antecedentes => {
//     if (!antecedentes.length) {
//       return false;
//     }
//     return antecedentes.includes('APPAPNP_3_08');
//   },
// };

export const Tz062101 = {
  id: '6.21.01',
  descripcion: () => (
    <span>
      Debido a que usted marcó Poliposis Adenomatosos, se le recomienda realizar una colonoscopía cada año a partir de
      los 12 años de edad. Además se recomienda un ultrasonido de tiroides a partir de los 18 años.
    </span>
  ),
  gruposTamizaje: [
    {
      rangosEdades: [RangoEdades.RANEDA_5_01, RangoEdades.RANEDA_5_02],
      aAPPAPNP: ['APPAPNP_3_08'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_08');
  },
};

export const Tz062102 = {
  id: '6.21.02',
  descripcion: () => (
    <span>
      Debido a que usted marcó Poliposis Adenomatosos, se le recomienda realizar una colonoscopía cada año a partir de
      los 12 años de edad. Además se recomienda un ultrasonido de tiroides a partir de los 18 años y una gastroscopia a
      partir de los 25 años (repetir según resultado).
    </span>
  ),
  gruposTamizaje: [
    {
      rangosEdades: [
        RangoEdades.RANEDA_5_03,
        RangoEdades.RANEDA_5_04,
        RangoEdades.RANEDA_5_05,
        RangoEdades.RANEDA_5_06,
        RangoEdades.RANEDA_5_07,
        RangoEdades.RANEDA_5_08,
        RangoEdades.RANEDA_5_09,
        RangoEdades.RANEDA_5_10,
        RangoEdades.RANEDA_5_11,
      ],
      aAPPAPNP: ['APPAPNP_3_08'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_08');
  },
};

export const Tz0622 = {
  id: '6.22',
  descripcion: () => (
    <span>
      Debido a que usted marcó adenoma gástrico, se debe de realizar una resección quirúrgica y una gastroscopía de
      control al año de la resección quirúrgica.
    </span>
  ),
  gruposTamizaje: [
    {
      aAPPAPNP: ['APPAPNP_3_09'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_09');
  },
};

export const Tz0623 = {
  id: '6.23',
  descripcion: () => (
    <span>
      Debido a que usted marcó metaplasia intestinal, se debe de realizar una gastroscopía cada tres años junto con
      biopsia.
    </span>
  ),
  gruposTamizaje: [
    {
      aAPPAPNP: ['APPAPNP_3_10'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_10');
  },
};

export const Tz0624 = {
  id: '6.24',
  descripcion: () => (
    <span>
      Debido a que usted marcó Síndrome Poliposo Juvenil, se debe de realizar una gastroscopía cada año a partir de los
      12 años.
    </span>
  ),
  gruposTamizaje: [
    {
      aAPPAPNP: ['APPAPNP_3_11'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_11');
  },
};

export const Tz0625 = {
  id: '6.25',
  descripcion: () => (
    <span>
      Debido a que usted marcó enfermedad intestinal inflamatoria, se debe de realizar una colonoscopía cada año. El
      control se debe de iniciar 10 años después del diagnóstico.
    </span>
  ),
  gruposTamizaje: [
    {
      aAPPAPNP: ['APPAPNP_3_16'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    return antecedentes.includes('APPAPNP_3_16');
  },
};

export const Tz0626 = {
  id: '6.26',
  descripcion: () => (
    <span>
      Debido a los antecedentes y síntomas que usted marcó, se debe de realizar una colonoscopía y consultar con un
      especialista en gastroenterología.
    </span>
  ),
  gruposTamizaje: [
    {
      sintomas: ['SINT_4_05', 'SINT_4_10', 'SINT_4_11', 'SINT_4_12'],
      aAPPAPNP: ['APPAPNP_3_12', 'APPAPNP_3_13', 'APPAPNP_3_15', 'APPAPNP_3_17'],
    },
  ],
  esAplicable: (antecedentes: any) => {
    if (!antecedentes.length) {
      return false;
    }
    let contador = 0;
    Tz0626.gruposTamizaje[0].sintomas.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    Tz0626.gruposTamizaje[0].aAPPAPNP.forEach((sintoma) => {
      if (antecedentes.includes(sintoma)) {
        contador++;
      }
    });
    return contador > 0;
  },
};
