export interface Paciente {
  Canton: string;
  Pais: string;
  Provincia: string;
  apellido1: string;
  apellido2: string;
  edad: string;
  email: string;
  escolaridad: string;
  estadoCivil: string;
  fechaNacimiento: Date;
  genero: string;
  ident: string;
  nacionalidad: string;
  nombre1: string;
  nombre2: string;
  religion: string;
  telf: string;
  terminos: boolean;
  tipoIdent: string;
}

export const EstadoCivil = {
  Soltero: 'Soltero(a)',
  UnionLibre: 'Unión Libre',
  Casado: 'Casado(a)',
  Divorciado: 'Divorciado(a)',
  Viudo: 'Viudo(a)',
};

export const RangoEdades = {
  RANEDA_5_01: '15-20',
  RANEDA_5_02: '21-25',
  RANEDA_5_03: '26-30',
  RANEDA_5_04: '31-35',
  RANEDA_5_05: '36-40',
  RANEDA_5_06: '41-45',
  RANEDA_5_07: '46-50',
  RANEDA_5_08: '51-55',
  RANEDA_5_09: '56-60',
  RANEDA_5_10: '61-65',
  RANEDA_5_11: '66-70',
  RANEDA_5_12: '71-75',
  RANEDA_5_13: '+75',
};

export const Genero = {
  GEN_1_1: 'Masculino',
  GEN_1_2: 'Femenino',
};

export const TipoIden = {
  Cedula: 'Cedula',
  CedulaResidencia: 'Cedula de residencia (DIMEX)',
  Pasaporte: 'Pasaporte',
  Otro: 'Otro',
};

export const Escolaridad = {
  'Sin Escolaridad': 'Sin Escolaridad',
  Primaria: 'Primaria',
  Secundaria: 'Secundaria',
  'Técnico Universitario': 'Técnico Universitario',
  'Bachillerato Universitario': 'Bachillerato Universitario',
  'Licenciatura Universitaria': 'Licenciatura Universitaria',
  'Maestría Universitaria': 'Maestría Universitaria',
  'Doctorado Universitario': 'Doctorado Universitario',
};

export const PacienteInitialValues: Paciente = {
  ident: '',
  tipoIdent: '',
  nombre1: '',
  nombre2: '',
  apellido1: '',
  apellido2: '',
  email: '',
  telf: '',
  escolaridad: '',
  estadoCivil: '',
  nacionalidad: '',
  religion: '',
  genero: '',
  fechaNacimiento: new Date(),
  edad: '',
  Pais: '',
  Provincia: '',
  Canton: '',
  terminos: false,
};
