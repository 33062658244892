import React from 'react';
import ReactDOM from 'react-dom';
import { AntecedentesContainer } from '../../containers/antecedentes';
import { PacienteContainer } from '../../containers/paciente';
import FormUserData from '../pasos/paso1';
import FormAntecedentes from '../pasos/paso2';
import Tamizaje from '../pasos/paso3';
import { PdfSection } from '../pdf-section';

const PortalCreator = (reactElement: React.ReactNode, domElementId: string): React.ReactPortal | null => {
  const domElement = document.getElementById(domElementId);
  if (domElement) {
    return ReactDOM.createPortal(reactElement, domElement);
  }
  console.error(`the DOM element ${domElementId} doesnt exist`);
  return null;
};

export const Main = () => {
  const PacienteProvider = PacienteContainer.Provider;
  const AntecedentesProvider = AntecedentesContainer.Provider;

  return (
    <PacienteProvider>
      <AntecedentesProvider>
        {PortalCreator(<FormUserData />, 'form-paciente')}
        {PortalCreator(<FormAntecedentes />, 'antecedentes')}
        {PortalCreator(<Tamizaje />, 'plantamizaje')}
        {PortalCreator(<PdfSection />, 'pdf-print')}
      </AntecedentesProvider>
    </PacienteProvider>
  );
};
