import React from 'react';

import { Grid, MenuItem, TextField } from '@material-ui/core';

const FormSelectField = (props) => (
  <Grid item xs={props.xs || 12} sm={props.sm || 4}>
    <TextField
      disabled={props.disabled}
      id={props.id}
      name={props.id}
      select
      label={props.label}
      value={props.values[props.id]}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      error={!!props.errors[props.id]}
      helperText={props.errors[props.id]}
      fullWidth
      variant="standard"
    >
      <MenuItem value="" />
      {props.options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  </Grid>
);

export default FormSelectField;
