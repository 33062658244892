import { Genero } from './paciente';

export const Sintom = {
  SINT_4_01: '¿Ha observado sangre en la orina?',
  SINT_4_02: '¿Ha observado sangre en la eyaculación?',
  SINT_4_03: 'Incontinencia urinaria',
  SINT_4_04: 'Imposibilidad de vaciamiento completo al orinar',
  SINT_4_05: 'Pérdida de peso no intencional',
  SINT_4_06: 'Nódulo o inflamación testicular',
  SINT_4_07: 'Sensación de peso en un testículo',
  SINT_4_08: 'Se presenta agradamiento de mamas inespecífico',
  SINT_4_09: 'Dolor testicular sordo',
  SINT_4_10: 'Saciedad temprana al comer',
  SINT_4_11: 'Dolor abdominal alto',
  SINT_4_12: 'Sangrado digestivo (vomito o heces con sangre)',
  SINT_4_13: 'Tos',
  SINT_4_14: 'Dificultad respiratoria',
  SINT_4_15: 'Dolor de pecho al respirar',
  SINT_4_16: 'Dolor de huesos',
  SINT_4_17: 'Cambio de tamaño en una sola mama',
  SINT_4_18: 'Cambios en la piel de la mama',
  SINT_4_19: 'Secreciones por el pezón',
  SINT_4_20: 'Inversión del pezón',
  SINT_4_21: 'Masa dura y localizada que duele y no alivia en mama',
};

export const SintomasGenero = {
  SINT_4_01: Genero.GEN_1_1,
  SINT_4_02: Genero.GEN_1_1,
  SINT_4_03: Genero.GEN_1_1,
  SINT_4_04: Genero.GEN_1_1,
  SINT_4_06: Genero.GEN_1_1,
  SINT_4_07: Genero.GEN_1_1,
  SINT_4_08: Genero.GEN_1_1,
  SINT_4_09: Genero.GEN_1_1,

  SINT_4_17: Genero.GEN_1_2,
  SINT_4_19: Genero.GEN_1_2,
  SINT_4_20: Genero.GEN_1_2,
  SINT_4_21: Genero.GEN_1_2,
};
