import React from 'react';
import { AntecedentesContainer } from '../../../containers/antecedentes';
import { PacienteContainer } from '../../../containers/paciente';
import { crearTamizajes } from '../../pasos/paso3/tamizaje';

export const TamizajesInfo = () => {
  const antecedentes = AntecedentesContainer.useContainer();
  const paciente = PacienteContainer.useContainer();

  const tamizajes = crearTamizajes(paciente.state, antecedentes.state.antecedentes);
  if (tamizajes.length) {
    return (
      <ol>
        {tamizajes.map((tamizaje) => {
          return <li key={tamizaje.id}>{tamizaje.descripcion()}</li>;
        })}
      </ol>
    );
  }
  return null;
};
